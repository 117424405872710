import * as singleSpa from "single-spa";
import * as isActive from "./activity-functions";
// registerApplication({
//   name: "@single-spa/welcome",
//   app: () =>
//     System.import<LifeCycles>(
//       "https://unpkg.com/single-spa-welcome/dist/single-spa-welcome.js"
//     ),
//   activeWhen: ["/"],
// });

singleSpa.addErrorHandler((err) => {
  console.log("Error from covetrus root config!!");
  console.log(err);
  if (singleSpa.getAppStatus(err.appOrParcelName) === singleSpa.LOAD_ERROR) {
    System.delete(System.resolve(err.appOrParcelName));
  }
});
singleSpa.registerApplication(
  "@covetrus/notifications",
   // @ts-ignore
  () => System.import("@covetrus/notifications"),
  (location) => isActive.nav()
);

singleSpa.registerApplication(
  "@covetrus/navbar",
  // @ts-ignore
  () => import("@covetrus/navbar"),
  (location) => isActive.nav()
);

singleSpa.registerApplication(
  "@covetrus/feature-toggles-admin",
  // @ts-ignore
  () => System.import("@covetrus/feature-toggles-admin"),
  (location) => isActive.featureToggles(location)
);

singleSpa.registerApplication(
  "@covetrus/hydration",
  // @ts-ignore
  () => System.import("@covetrus/hydration"),
  (location) => isActive.hydration(location)
); 

singleSpa.registerApplication(
  "@covetrus/report-admin",
  // @ts-ignore
  () => System.import("@covetrus/report-admin"),
  (location) => isActive.report(location)
); 

singleSpa.registerApplication(
  "@covetrus/contractmanagement",
  // @ts-ignore
  () => System.import("@covetrus/contractmanagement"),
  (location) => isActive.contractManagement(location)
); 

singleSpa.start({
  urlRerouteOnly: true,
});
