export function sufix(location, ...sufixes) {
  return sufixes.some((sufix) =>
    location.pathname.toLocaleLowerCase().includes(sufix)
  );
}

export function nav() {
  // The nav is always active
  return true;
}

export function clientSearch(location) {
  return sufix(location, "/client-search");
}

export function featureToggles(location) {
  return sufix(location, "/featuretoggles/general");
}

export function hydration(location) {
  return sufix(location, "/hydration");
} 

 export function report(location) {
  return sufix(location, "/administration/pbireports");
} 

export function contractManagement(location) {
  return sufix(location, "/administration/contractmanagement");
} 